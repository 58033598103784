import { ChangeEvent } from 'react';
import { useCompleteWith } from '@cs/hooks';
import { AdditionalPropertiesDto } from '@cs/state/model';
import { Input } from '@facephi/ui-react';
import { WidgetProps } from '@rjsf/utils';
import { JSONSchema7TypeName } from 'json-schema';
import { useTranslation } from 'react-i18next';

export const InputCustom = ({
  value = '',
  onChange,
  label,
  placeholder,
  disabled,
  rawErrors,
  schema,
  required,
  id,
}: WidgetProps) => {
  const { t } = useTranslation();

  const getType = (type: JSONSchema7TypeName | JSONSchema7TypeName[]) => {
    if (Array.isArray(type)) {
      return type.filter((ele) => ele !== 'null')?.[0] === 'number'
        ? 'number'
        : 'text';
    } else {
      return type === 'number' ? 'number' : 'text';
    }
  };

  useCompleteWith(
    schema.additionalProperties as AdditionalPropertiesDto,
    onChange,
  );

  return (
    <Input
      value={value}
      id={id}
      onChange={(event: ChangeEvent<HTMLInputElement>) =>
        onChange(event.target.value)
      }
    
      type={getType(schema.type as any)}
      label={t(label) + `${required ? ' *' : ''}`}
      disabled={disabled}
      placeholder={placeholder}
      errorLabel={rawErrors?.[0]}
      testId="test-input-json-schema"
    />
  );
};
