
import { useState } from 'react';

export function useLoginRoute() {
  const [route, setRoute] = useState<string | null>(
    sessionStorage.getItem('route'),
  );

  const saveRoute = () => {
    try {
      setRoute(window.location.href);
      sessionStorage.setItem('route', window.location.href);
    } catch (e) {
      console.warn(e);
    }
  };

  return {
    route,
    saveRoute,
  };
}
