
import { useEffect } from 'react';
import { useLicenseJson, JsonValuesDto } from '@cs/hooks';
import { TextArea } from '@facephi/ui-react';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type IProps = {
  onChange: (value: JsonValuesDto) => void;
};

export const CopyJsonInput = ({ onChange }: IProps) => {
  const { t } = useTranslation();

  const { register, control } = useForm();

  const jsonValue = useWatch({ control, name: 'json' });
  const { values, error } = useLicenseJson(jsonValue);

  useEffect(() => {
    if (values) {
      onChange(values);
    }
  }, [values]);

  return (
    <>
      <TextArea
        {...register('json')}
        placeholder={t('Copy json to format values')}
        errorLabel={error ? t('This format is not valid') : undefined}
      />
    </>
  );
};
