import { gql } from '@apollo/client';
import { userFragment, userTenantFragment } from '../fragments';

export const getUser = gql`
  ${userFragment}
  query getUser($userId: uuid!) {
    users_by_pk(id: $userId) {
      ...usersFields
    }
  }
`;

export const listUsers = gql`
  ${userTenantFragment}
  ${userFragment}
  query getUsers($clientId: uuid!, $name: String, $offset: Int, $limit: Int) {
    users: users_clients(
      where: {
        client_id: { _eq: $clientId }
        user: {
          _or: [
            { email: { _ilike: $name } }
            { first_name: { _ilike: $name } }
            { last_name: { _ilike: $name } }
          ]
        }
      }
      offset: $offset
      limit: $limit
      order_by: { created_at: desc }
    ) {
      user {
        ...usersFields
        users_tenants(
          where: {
            tenant: {
              deleted_at: { _is_null: true }
              client_id: { _eq: $clientId }
            }
          }
        ) {
          id
          tenant {
            id
            name
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
    users_aggregate: users_clients_aggregate(
      where: {
        user: {
          _or: [
            { email: { _ilike: $name } }
            { first_name: { _ilike: $name } }
            { last_name: { _ilike: $name } }
          ]
        }
      }
    ) {
      aggregate {
        count
        __typename
      }
      __typename
    }
  }
`;

export const getUsersByClient = gql`
  query getUsersByClient($clientId: uuid) {
    users(where: { client_id: { _eq: $clientId } }) {
      id
      email
    }
  }
`;

export const getTenantsByUser = gql`
  ${userTenantFragment}
  query getTenantsUser($userId: uuid!, $clientId: uuid!) {
    users_tenants(
      where: {
        user_id: { _eq: $userId }
        tenant: {
          deleted_at: { _is_null: true }
          client_id: { _eq: $clientId }
        }
      }
    ) {
      id
      active: default
      tenant {
        ...userTenantFields
      }
    }
  }
`;

export const getUsersSearch = gql`
  query searchUser($search: String!, $clientId: uuid!) {
    users: searchUser(search: $search, clientId: $clientId) {
      users {
        email
        firstName
        lastName
        id
      }
      exist
    }
  }
`;
