import { useWatch } from 'react-hook-form';

import { generateSlug } from '../utils';

type IUseSlug = {
  name: string;

  control: any;
};

export function useSlug({ control, name }: IUseSlug) {
  const value = useWatch({
    control,
    name,
  });

  return { value: value ? generateSlug(value) : undefined };
}
