import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useCopyToClipboard } from '@cs/hooks';
import {
  ApiKeyDto,
  APiKeyItemDto,
  modalApiKeySchema,
  RestrictionsApiKey,
} from '@cs/state/model';
import { insertApiKey, updateApiKey } from '@cs/state/mutations';
import { getAllServicesByClient, getAllServices } from '@cs/state/queries';
import { generateApiKey } from '@cs/utils';
import {
  Button,
  DropdownSearch,
  DropdownSearchOption,
  FlexContainer,
  Input,
  Label,
  ModalStateProps,
  RadioButton,
  RadioGroup,
  TabPanel,
  TabsHorizontal,
} from '@facephi/ui-react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  ModalApiKeyInputRow,
  ModalApiKeyMessage,
  ModalApiKeyStyles,
} from './Styles';

type ListServicesTypes = {
  services: {
    id: string;
    name?: string;
    service?: {
      name: string;
      id: string;
    };
  }[];
};

type IProps = ModalStateProps & {
  data?: APiKeyItemDto | undefined;
  onRefetch?(): void;
  admin?: boolean;
};

type CustomDropdown = DropdownSearchOption & {
  id?: string;
};

const restrictions = [
  {
    title: 'None',
    value: RestrictionsApiKey.none,
  },
  {
    title: 'Reference urls',
    value: RestrictionsApiKey.urls,
    disabled: true,
  },
  {
    title: 'IP addresses',
    value: RestrictionsApiKey.ip,
    disabled: true,
  },
  {
    title: 'Apps for Android',
    value: RestrictionsApiKey.android,
    options: ['bundleId', 'fingerprint'],
  },
  {
    title: 'Apps for iOS',
    value: RestrictionsApiKey.ios,
    options: ['bundleId'],
  },
];

export const ModalApiKey = ({
  show,
  onChangeShow,
  data,
  admin = false,
  onRefetch,
}: IProps) => {
  const apiKeyLength = 40;
  const { t, i18n } = useTranslation();
  const [innerApiKey, setInnerApiKey] = useState<string>(data?.api_key || '');
  const { copy } = useCopyToClipboard();
  const params = useParams();

  const getConfigRestriction = (
    config?: APiKeyItemDto['configuration'],
  ): RestrictionsApiKey => {
    let option;
    if (config) {
      option = Object.keys(config)[0] as RestrictionsApiKey;
    }
    return option || RestrictionsApiKey.none;
  };

  const [activedRestriction, setActivedRestriction] =
    useState<RestrictionsApiKey>(getConfigRestriction(data?.configuration));

  const { data: listServices } = useQuery<ListServicesTypes>(
    admin ? getAllServices : getAllServicesByClient,
    {
      variables: admin
        ? undefined
        : {
            clientId: params?.id,
          },
      fetchPolicy: 'network-only',
    },
  );
  const [addApiKey] = useMutation(insertApiKey);
  const [saveApiKey] = useMutation(updateApiKey);

  const services = useMemo(
    () =>
      admin
        ? listServices?.services.map((item) => ({
            name: item.name as string,
            value: item.id,
          }))
        : listServices?.services.reduce((acc, item) => {
            acc.push({
              name: item.service ? item.service.name : '',
              id: item.id,
              value: item.service?.id as string,
            });
            return acc;
          }, [] as CustomDropdown[]) || [],
    [listServices],
  );

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    control,
    formState: { errors },
  } = useForm<ApiKeyDto>({
    resolver: yupResolver(modalApiKeySchema),
    defaultValues: {
      ...data,
      ...Object.values(data?.configuration || [])[0],
      restriction: getConfigRestriction(data?.configuration),
      services: data?.api_keys_services.map((item) => item.service_id),
    },
  });

  const selectedServices = useWatch({
    control,
    name: 'services',
  });

  const handleApiKey = () => {
    setInnerApiKey(generateApiKey(apiKeyLength));
  };

  useEffect(() => {
    reset();
    if (show && !data) {
      handleApiKey();
    }
  }, [show]);

  useEffect(() => {
    setValue('apiKey', innerApiKey);
  }, [innerApiKey]);

  const getConfigRestrictions = (form: ApiKeyDto) => {
    const options = restrictions.find(
      (item) => item.value === form.restriction,
    )?.options;
    if (!options) return;
    return options.reduce(
      (acc, item) => {
        acc[form.restriction] = {
          ...acc[form.restriction],
          [item]: form[item as keyof ApiKeyDto],
        };
        return acc;
      },
      { [form.restriction]: {} },
    );
  };

  const onSubmit = async (form: ApiKeyDto) => {
    const configuration = getConfigRestrictions(form);
    const variables = {
      api_key: form.apiKey,
      name: form.name,
      configuration,
    };

    if (data) {
      await saveApiKey({
        variables: {
          id: data.id,
          apiKey: variables,
        },
      });
    } else {
      const servicesList =
        form.services.length && services
          ? (services as any)
              .filter((item: any) => form.services.includes(item.value))
              .map((item: any) => ({
                serviceId: item.value,
                serviceClientId: item.id,
              }))
          : [];

      await addApiKey({
        variables: {
          apiKey: {
            name: form.name,
            api_key: form.apiKey,
            client_id: params.id,
            services: servicesList,
          },
        },
      });
    }

    onChangeShow(false);
    onRefetch && onRefetch();
  };

  return (
    <ModalApiKeyStyles
      header={{
        iconName: 'PlusCircle',
        title: t('Api key data'),
      }}
      footer={{
        closeButtonLabel: t('Cancel'),
        submitButtonLabel: data ? t('Edit') : t('Generate'),
        onSubmitButton: handleSubmit(onSubmit),
      }}
      hasPadding
      show={show}
      onChangeShow={onChangeShow}
      isForm
    >
      <form>
        <ModalApiKeyInputRow
          justifyContent="space-between"
          forwardedAs="section"
        >
          <Input
            {...register('name')}
            label="Name"
            placeholder={t('Api key')}
            errorLabel={errors.name?.message && t(errors.name.message)}
          />
          <div>
            <Input
              {...register('apiKey')}
              label={t('API Keys')}
              iconRight="Copy"
              onClickIconRight={() => copy(innerApiKey)}
              readOnly
            />
            <Button
              variant="text"
              iconName="ArrowClockwise"
              color="primary"
              semiBold={false}
              onClick={handleApiKey}
            >
              {t('Regenerate')}
            </Button>
          </div>
        </ModalApiKeyInputRow>
        <Label fontSize="16" semibold>
          {t('Service restrictions')}
        </Label>
        <ModalApiKeyMessage fontSize="14">
          {t(
            'Service restrictions control which services can use your API key. You can configure multiple services or none per key .',
          )}
        </ModalApiKeyMessage>

        <ModalApiKeyInputRow
          justifyContent="space-between"
          forwardedAs="section"
          hasMargin
        >
          <Controller
            name="services"
            control={control}
          
            render={({ field }: any) => {
              return (
                <DropdownSearch
                  {...field}
                  name="services"
                  control={control}
                  placeholder={t('Select services')}
                  label={t('Services')}
                  options={services}
                  locale={i18n.language}
                  multiple
                />
              );
            }}
          />
        </ModalApiKeyInputRow>
        {!selectedServices?.length && (
          <ModalApiKeyMessage fontSize="12">
            {t('No services included')}
          </ModalApiKeyMessage>
        )}

        <Label fontSize="16" semibold>
          {t('Application restrictions')}
        </Label>
        <ModalApiKeyMessage fontSize="14">
          {t(
            'Application restrictions control which websites, IP addresses or applications can use your API key. You can configure an application restriction per key.',
          )}
        </ModalApiKeyMessage>

        <FlexContainer columnGap="2" justifyContent="space-between">
          <RadioGroup
            {...register('restriction')}
            name="restriction"
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              setActivedRestriction(event.target.value as RestrictionsApiKey);
            }}
            defaultSelected={getConfigRestriction(data?.configuration)}
          >
            {restrictions.map((item) => (
              <RadioButton
                key={item.value}
                label={t(item.title)}
                value={item.value}
                disabled={item.disabled}
              />
            ))}
          </RadioGroup>
          <ModalApiKeyInputRow>
            <TabsHorizontal active={activedRestriction} noPadding>
              <TabPanel id={RestrictionsApiKey.ios}>
                <Input
                  {...register('bundleId')}
                  name="bundleId"
                  label={t('Bundle ID')}
                  errorLabel={
                    errors.bundleId?.message && t(errors.bundleId.message)
                  }
                />
              </TabPanel>

              <TabPanel id={RestrictionsApiKey.android}>
                <FlexContainer rowGap="1.2" flexDirection="column">
                  <Input
                    {...register('bundleId')}
                    name="bundleId"
                    label={t('Bundle ID')}
                    errorLabel={
                      errors.bundleId?.message && t(errors.bundleId.message)
                    }
                  />
                  <Input
                    {...register('fingerprint')}
                    name="fingerprint"
                    label={t('Fingerprint')}
                    errorLabel={
                      errors.fingerprint?.message &&
                      t(errors.fingerprint.message)
                    }
                  />
                </FlexContainer>
              </TabPanel>
            </TabsHorizontal>
          </ModalApiKeyInputRow>
        </FlexContainer>
      </form>
    </ModalApiKeyStyles>
  );
};
