import { useMemo } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
  CardLeftUserIdElements,
  CardLeftUserIdHeader,
  ProfileCardContent,
} from '@cs/components';
import { usePermissions } from '@cs/hooks';
import {
  createTenantSchema,
  Language,
  SCOPES,
  Tenant,
  Timezone,
} from '@cs/state/model';
import { modifyTenant } from '@cs/state/mutations';
import { getLanguages, getTimezones } from '@cs/state/queries';
import {
  Button,
  CardBase,
  CardBaseFooter,
  DropdownSearch,
  DropdownSearchOption,
  FlexContainer,
  Input,
  Label,
  useToast,
} from '@facephi/ui-react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export const CardLeftTenantId = ({ tenant }: { tenant: Tenant }) => {
  const { t, i18n } = useTranslation();
  const [editTenant] = useMutation(modifyTenant);
  const { toastManager } = useToast();

  const { data: dataLanguages } = useQuery<{ languages: Language[] }>(
    getLanguages,
  );

  const optionsLanguages: DropdownSearchOption[] = useMemo(
    () =>
      dataLanguages
        ? dataLanguages.languages.map((item) => ({
            name: item.name,
            value: item.code,
          }))
        : [],
    [dataLanguages],
  );

  const { data } = useQuery<{ timezone: Timezone[] }>(getTimezones);

  const optionsTimeZone: DropdownSearchOption[] = useMemo(
    () =>
      data
        ? data.timezone.map((item) => ({
            name: item.timezone,
            value: item.timezone,
          }))
        : [],
    [data],
  );

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FieldValues>({
    resolver: yupResolver(createTenantSchema),
    mode: 'onChange',
    defaultValues: tenant,
  });

  const { hasPermission } = usePermissions();

  const onSubmit = async (values: FieldValues) => {
    await editTenant({
      variables: {
        id: tenant.id,
        tenant: {
          name: values.name,
          address: values.address,
          timezone: values.timezone,
          language: values.language,
        },
      },
      onCompleted: () => {
        toastManager({
          type: 'success',
          message: t('Tenant modified successfully'),
          testId: 'toast-modify-tenant',
        });
      },
    });
  };

  return (
    <CardBase flexDirection="column" flex="2">
      <CardLeftUserIdHeader>
        <Label fontSize="16" semibold>
          {t('Tenant information')}
        </Label>
      </CardLeftUserIdHeader>

      <ProfileCardContent
        forwardedAs="form"
        flexDirection="column"
        rowGap="3.8"
        flex="1"
      >
        <FlexContainer as="section" rowGap="2" flexDirection="column">
          <CardLeftUserIdElements columnGap="7">
            <Input
              testId="input-name"
              label={t('Name')}
              {...register('name')}
              errorLabel={errors.name?.message && t(errors.name.message)}
              disabled={
                hasPermission([SCOPES.canEditTenantName]) ? false : true
              }
            />

            <Input
              testId="input-address"
              label={t('Address')}
              {...register('address')}
            />
          </CardLeftUserIdElements>
        </FlexContainer>

        <FlexContainer as="section" rowGap="2" flexDirection="column">
          <Label fontSize="16" semibold>
            {t('Language & Timezone')}
          </Label>

          <CardLeftUserIdElements columnGap="7">
            <Controller
              name="language"
              control={control}
            
              render={({ field }: any) => (
                <DropdownSearch
                  {...field}
                  testId="dropdown-languages"
                  label={t('Language')}
                  options={optionsLanguages}
                  iconLeft="Flag"
                  locale={i18n.language}
                  placeholder={t('Select language')}
                />
              )}
            />
            <Controller
              name="timezone"
              control={control}
            
              render={({ field }: any) => (
                <DropdownSearch
                  {...field}
                  testId="dropdown-timezone"
                  label={t('Timezone')}
                  options={optionsTimeZone}
                  iconLeft="MapTrifold"
                  locale={i18n.language}
                  placeholder={t('Select timezone')}
                />
              )}
            />
          </CardLeftUserIdElements>
        </FlexContainer>
      </ProfileCardContent>

      <CardBaseFooter as="section" hasPadding>
        <Button
          type="submit"
          testId="button-modify-tenant"
          onClick={handleSubmit(onSubmit)}
          size="L"
        >
          {t('Save')}
        </Button>
      </CardBaseFooter>
    </CardBase>
  );
};
