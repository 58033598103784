import { lazy, useEffect } from 'react';
import { useLoginRoute } from '@cs/hooks';
import { LayoutProvider } from '@cs/providers';
import { Spinner } from '@facephi/ui-react';
import { useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';

const Page404 = lazy(() => import('../pages/Error404'));

export const ProtectedRoute = ({ component: Component, flags }: any) => {
  const { isAuthenticated, isLoading } = useAuth();
  const navigate = useNavigate();
  const isActive = () => !flags || !flags.some((element: boolean) => !element);
  const canAccess = () => !isLoading && isAuthenticated && isActive();
  const { saveRoute } = useLoginRoute();

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      saveRoute();
      return navigate('/login');
    }
  }, [isAuthenticated, isLoading]);

  if (!isActive()) {
    return <Page404 />;
  }

  return canAccess() ? (
    <LayoutProvider>
      <Component />
    </LayoutProvider>
  ) : (
    <Spinner />
  );
};
