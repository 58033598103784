import React from 'react';
import { useMutation } from '@apollo/client';
import { PermissionsGate } from '@cs/components';
import { RoutesName } from '@cs/state/constants';
import { SCOPES, Service, ServiceListType, TableProps } from '@cs/state/model';
import { removeService } from '@cs/state/mutations';
import {
  ButtonIcon,
  OptionMenu,
  OptionMenuItem,
  PositionX,
  TableLegacy as TableSelection,
  useToast,
} from '@facephi/ui-react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { CellProps } from 'react-table';

type ServiceTableData = {
  name: string;
  id: string;
};

type Props = TableProps & {
  services?: ServiceListType[];
  hasMore?: boolean;
  scrollPosition?: number;
  fetchMore?(): Promise<boolean>;
  onEdit(service: Service): void;
};

export const TableServices = ({
  services,
  hasMore,
  loading,
  onEdit,
  fetchMore,
  scrollPosition,
  emptyStateData,
}: Props) => {
  const { t, i18n } = useTranslation();
  const [deleteService] = useMutation(removeService);
  const { toastManager } = useToast();
  const navigate = useNavigate();
  const routeParams = useParams();

  const onDeleteService = async (id: string) => {
    await deleteService({
      variables: { id },
      update: (cache) => {
        cache.modify({
          fields: {
            clients_services(existingRefs, { readField }) {
              return existingRefs.filter(
                (item: ServiceListType) => id !== readField('id', item),
              );
            },
          },
        });
      },
      onError: () => {
        toastManager({
          type: 'error',
          message: t('Something went wrong, try again'),
          testId: 'toast-delete-service',
        });
      },
      onCompleted: () => {
        toastManager({
          type: 'success',
          message: t('Service assigned deleted'),
          testId: 'toast-delete-service',
          duration: 1500,
        });
      },
    });
  };

  const columns = React.useMemo(
    () => [
      {
        Header: t('Name'),
        accessor: 'name',
      },
      {
        Header: t('Actions'),
        accessor: 'actions',
        Cell: (props: CellProps<ServiceTableData>) => {
          return (
            <OptionMenu
              hidePanelOnClick
              widthAuto
              renderItem={<ButtonIcon variant="text" iconName="ListPlus" />}
              positionX={PositionX.end}
              testId="option-menu-service"
            >
              <OptionMenuItem
                testId="button-view-licence"
                iconName="MagnifyingGlassPlus"
                onClick={() => {
                  navigate(
                    generatePath(RoutesName.licensesByService, {
                      clientId: routeParams.id,
                      serviceId: props.row.original.id,
                    }),
                  );
                }}
              >
                {t('Licenses')}
              </OptionMenuItem>
              <PermissionsGate scopes={[SCOPES.canWriteServices]}>
                <OptionMenuItem
                  testId="button-edit-service"
                  onClick={() => onEdit(props.row.original as Service)}
                  iconName="NotePencil"
                >
                  {t('Edit')}
                </OptionMenuItem>
              </PermissionsGate>
              <PermissionsGate scopes={[SCOPES.canDeleteServices]}>
                <OptionMenuItem
                  testId="button-delete-service"
                  onClick={() => onDeleteService(props.row.original.id)}
                  iconName="Trash"
                  color="error"
                >
                  {t('Delete')}
                </OptionMenuItem>
              </PermissionsGate>
            </OptionMenu>
          );
        },
        maxWidth: 100,
      },
    ],
    [],
  );

  const tableData = React.useMemo(() => {
    return (
      services?.map((item) => ({
        name: item.service.name,
        id: item.id,
        serviceId: item.service.id,
        action: '',
      })) || []
    );
  }, [services]);

  return (
    <TableSelection
    
      columns={columns as any}
      data={tableData}
      loading={loading}
      hasMore={hasMore}
      fetchMore={fetchMore}
      scrollPosition={scrollPosition}
      emptyStateData={emptyStateData}
      locale={i18n.language}
    />
  );
};
