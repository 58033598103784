
import { ReactElement, useMemo } from 'react';
import { useDelete } from '@cs/hooks';
import { RoutesName } from '@cs/state/constants';
import { TablePaginateProps, UserListDto } from '@cs/state/model';
import { removeUser, undoRemoveUser } from '@cs/state/mutations';
import {
  ButtonIcon,
  Label,
  OptionMenu,
  OptionMenuItem,
  PositionX,
  Status,
  TablePagination,
} from '@facephi/ui-react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { CellProps } from 'react-table';
import { TablePanelButton } from '../tenants/Styles';

type UserTableData = {
  name: string;
  userId: string;
  avatarId: string;
  userTenants: ReactElement;
  active: boolean;
};

type Props = TablePaginateProps & {
  users?: UserListDto[];
  onBlock(id: string, active: boolean): void;
};

export const UsersTable = ({
  users,
  loading,
  emptyStateData,
  onBlock,
  totalPages,
  changePage,
  currentPage,
}: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const routeParams = useParams();
  const { handleDelete } = useDelete(removeUser, undoRemoveUser, 'users');

  const columns = useMemo(
    () => [
      {
        header: t('Email (ID)'),
        accessorKey: 'email',
        maxWidth: 400,
      },
      {
        header: t('Tenants'),
        accessorKey: 'userTenants',
        cell: (props: CellProps<UserTableData>) => {
          const user = props.row.original as any;

          return user.userTenants?.length ? (
            <OptionMenu
              renderItem={
                <TablePanelButton
                  variant="text"
                  iconName="CaretDown"
                  iconPosition="right"
                >
                  {`${user.userTenants.length} ${t('Tenants')}`}
                </TablePanelButton>
              }
            >
              {user.userTenants.map((userTenant: any) =>
                userTenant.tenant ? (
                  <OptionMenuItem key={userTenant.tenant.id}>
                    {userTenant.tenant?.name}
                  </OptionMenuItem>
                ) : null,
              )}
            </OptionMenu>
          ) : (
            <Label fontSize="14">{t('No tenants')}</Label>
          );
        },
      },
      {
        header: t('Active'),
        accessorKey: 'active',
        maxWidth: 150,
        cell: (props: CellProps<UserTableData>) => {
          return (
            <Status
              status={props.row.original.active ? 'SUCCEEDED' : 'DENIED'}
              testId="user-active"
            />
          );
        },
      },
      {
        header: t('Action'),
        accessorKey: 'action',
        maxWidth: 60,
        cell: (props: CellProps<UserTableData>) => {
          return (
            <OptionMenu
              hidePanelOnClick
              renderItem={<ButtonIcon variant="text" iconName="ListPlus" />}
              positionX={PositionX.end}
              testId="option-menu-user"
            >
              <OptionMenuItem
                testId="button-edit-user"
                iconName="MagnifyingGlassPlus"
                onClick={(event) => {
                  navigate(
                    generatePath(RoutesName.userId, {
                      clientId: routeParams.id,
                      userId: props.row.original.userId,
                    }),
                  );
                  event.stopPropagation();
                }}
              >
                {t('Edit')}
              </OptionMenuItem>

              <OptionMenuItem
                testId="button-block-user"
                iconName={
                  props.row.original.active ? 'Prohibit' : 'CheckCircle'
                }
                onClick={(event) => {
                  onBlock(props.row.original.userId, props.row.original.active);
                  event.stopPropagation();
                }}
              >
                {t(props.row.original.active ? 'Block' : 'Unblock')}
              </OptionMenuItem>
              <OptionMenuItem
                testId="button-delete-user"
                onClick={(event) => {
                  handleDelete(props.row.original.userId);
                  event.stopPropagation();
                }}
                iconName="Trash"
                color="error"
              >
                {t('Delete')}
              </OptionMenuItem>
            </OptionMenu>
          );
        },
      },
    ],
    [],
  );

  const tableData = useMemo(() => {
    return (
      users?.map((user: UserListDto) => ({
        id: user.id,
        userId: user.id,
        email: user.email,
        name: user.name,
        userTenants: user.users_tenants,
        tenant: '',
        active: user.active,
        avatarId: user.avatar || '',
      })) || []
    );
  }, [users]);

  return (
    <>
      <TablePagination
        columns={columns as any}
        data={tableData}
        loading={loading}
        emptyStateData={emptyStateData}
        onChangePage={changePage}
        totalElements={totalPages}
        currentPage={currentPage}
        onClickCell={(data: any) => {
          navigate(
            generatePath(RoutesName.userId, {
              clientId: routeParams.id,
              userId: data.userId,
            }),
          );
        }}
      />
    </>
  );
};
