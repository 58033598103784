
import { useState, useEffect } from 'react';
import { MaxItemsPag } from '@cs/state/constants';

export type IPaginationProps = {
  hasMore?: boolean;
  scrollPosition?: number;
  fetchMore?(): Promise<boolean>;
};

export function usePagination(
  data: Array<any>,
  loading: boolean,
  fetchMore: any,
  total: number,
) {
  const [scrollPosition, setScrollPosition] = useState<number>();
  const [offset, setOffset] = useState<number>(MaxItemsPag);
  const [hasMore, setHasMore] = useState<boolean>(true);

  useEffect(() => {
    if (data) {
      if (data.length > total) {
        setHasMore(false);
      }
    }
  }, [data]);

  const handlePagination = async (): Promise<boolean> => {
    if (hasMore && !loading) {
      await fetchMore({
        variables: {
          offset,
        },
      });

      const newOffset = offset + MaxItemsPag;

      if (newOffset >= total) {
        setHasMore(false);
      }

      setOffset((offset) => offset + MaxItemsPag);
    }

    return Promise.resolve(true);
  };

  return {
    scrollPosition,
    hasMore,
    handlePagination,
    setScrollPosition,
  };
}
